@keyframes beat {
    0%, 100% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
  
  .loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-icon {
    width: 100px; /* Adjust based on your icon's size */
    height: 100px; /* Adjust based on your icon's size */
    background-image: url('../../public/assets/creative_logo.png');
    background-size: contain;
    animation: beat 1s infinite;
  }
  
  /* background-image: url('../../public/assets/user.png'); */
  /* background-image: url('./path/to/iconNow.png'); */