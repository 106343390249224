.circle-number-red {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 25px;
    border-radius: 50%;
    background-color: #db4f4a;
    font-size: 15px;
    font-weight: bold;
  }

.circle-number-yellow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 25px;
    border-radius: 50%;
    background-color: #c3ae39;
    font-size: 15px;
    font-weight: bold;
  }

.circle-number-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 25px;
    border-radius: 50%;
    background-color: #4cceac;
    font-size: 15px;
    font-weight: bold;
  }

  

/* Spinner CSS */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}